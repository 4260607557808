import React, { useRef, useState } from 'react'
import './login.css'
import { useAuth } from '../../contexts/AuthProvider'

const Login = () => {
    const { login } = useAuth()
    let loginEmailRef = useRef();
    let loginPasswordRef = useRef();
    let [error, setError] = useState('')

    async function loginHandler(event){
        event.preventDefault()
        try{
            setError("")
            await login(loginEmailRef.current.value, loginPasswordRef.current.value)
        }catch(err){
            setError(err.code.slice(5,err.code.length).replaceAll('-', ' '))
        }
    }

    return (
        <div className="body login">
            <h1>menzberg</h1>
            <div className="form-container">
                {error &&
                    <div className="alert">
                        {error}
                    </div>
                }
                <form action="/login" method="post">
                    <div className="con">
                        <input type="email" name="email" ref={loginEmailRef} placeholder="Email"/>
                    </div>
                    <div className="con">
                        <input type="password" name="password" ref={loginPasswordRef} placeholder="mot de passe"/>
                    </div>
                    <div className="con">
                        <button onClick={loginHandler}>se connecter</button>
                    </div>
                </form>
            </div>

        </div>
    )
}

export default Login