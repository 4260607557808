import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthProvider';
import { useNavigate } from 'react-router-dom';
import './home.css';
import Row from '../../components/Row';
import { collection, where, query, updateDoc, onSnapshot, doc, orderBy } from 'firebase/firestore';
import { db } from '../../firebase/firebase';


const Home = () => {
  const [items, setItems] = useState([]);
  const [checkAll, setCheckAll] = useState(false);
  const [message, setMessage] = useState('');
  const [messageState, setMessageState] = useState('');
  const [showArchived, setShowArchived] = useState(false); // New state to toggle archived orders
  const [isLoading, setIsLoading] = useState(false)

  const messageDisplay = (msg) => {
    setMessage(msg);
    setTimeout(() => {
      setMessage('');
      setMessageState('');
    }, 3000);
  };

  // Fetch orders based on whether archived or active orders should be shown
  useEffect(() => {
    const docsRef = query(
      collection(db, 'orders'),
      where('archived', '==', showArchived),
      orderBy('id', 'desc')
    );

    const unsubscribe = onSnapshot(docsRef, (snapshot) => {
      const data = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
        checked: false,
      }));

      setItems(data); // Update state with real-time data
    });

    return () => unsubscribe();
  }, [showArchived]); // Listen for changes to `showArchived` state

  const handleCheckAll = (e) => {
    const isChecked = e.target.checked;
    setCheckAll(isChecked);

    const updatedItems = items.map((item) => ({ ...item, checked: isChecked }));
    setItems(updatedItems);
  };

  const handleItemCheck = (id) => {
    const updatedItems = items.map((item) =>
      item.id === id ? { ...item, checked: !item.checked } : item
    );
    setItems(updatedItems);

    const allChecked = updatedItems.every((item) => item.checked);
    setCheckAll(allChecked);
  };

  // farward all the selected items to first delivery when the button is clicked
  let deliverFn = (item)=>{
    if(item.order.shippingInfo.title !=="Usine"){
        let client = {
            nom: `${item.order.billingInfo.contactDetails.firstName} ${item.order.billingInfo.contactDetails.lastName}`,
            gouvernerat: `${item.order.extendedFields.namespaces._user_fields.gouvernerat}`, // mch mawjoud
            // gouvernerat: `Jendouba`, // mch mawjoud
            ville: `${item.order.shippingInfo.logistics.shippingDestination.address.city}`,
            adresse: `${item.order.shippingInfo.logistics.shippingDestination.address.city} ${item.order.shippingInfo.logistics.shippingDestination.address.addressLine1} ${item.order.shippingInfo.logistics.shippingDestination.address.postalCode}`,
            telephone: `${item.order.shippingInfo.logistics.shippingDestination.contactDetails.phone}`,
            telephone2: "" 
        }
        // console.log('client:', client)
        let ar = ''
        let des = ''
        for (let i = 0; i <item.order.lineItems.length; i++){
            if(i===0){
                ar = `${ar}${item.order.lineItems[i].productName.original}`
                des = `${des}${item.order.lineItems[i].productName.original} `
                for (let j = 0; j < item.order.lineItems[i].descriptionLines.length; j++) {
                    let txt = item.order.lineItems[i].descriptionLines[j].plainText?.original
                    // console.log(txt)
                    let color = item.order.lineItems[i].descriptionLines[j]?.color
                    const e = `${item.order.lineItems[i].descriptionLines[j].name?.original}: ${txt ? txt : ''}${color ? color : ''}`;
                    des = `${des} ${e}`
                }
                des = `${des} X${item.order.lineItems[i].quantity}`
            }
            else{
                ar = `${ar} | ${item.order.lineItems[i].productName.original}`
                des = `${des} | ${item.order.lineItems[i].productName.original} `
                for (let j = 0; j < item.order.lineItems[i].descriptionLines.length; j++) {
                    // let desc = item.order.lineItems[i].descriptionLines[j]
                    let txt = item.order.lineItems[i].descriptionLines[j].plainText?.original
                    // console.log(txt)
                    let color = item.order.lineItems[i].descriptionLines[j]?.color
                    const e = `${item.order.lineItems[i].descriptionLines[j].name?.original}: ${txt ? txt: ''}${color ? color: ''}`;
                    des = `${des} ${e}`
                }
                des = `${des} X${item.order.lineItems[i].quantity}`
            }
            // products.push(pr)
        }
        let products = { // chihemou
            article: ar,
            prix: Number(item.order.priceSummary.total.amount),
            designation: des, 
            nombreArticle: item.order.lineItems.reduce((acc, product) => acc + product.quantity, 0),  
            commentaire: `${item.order.number}`,
            // nombreEchange: 0
        }
        // console.log('products', products)
        let delivery = {
                Client: client,
                Produit: products
            }
            // console.log(delivery)
        return delivery
    }else{
        return false
    }
}
  const forward1Handler = async (via) => {
    setIsLoading(true)
    const selectedItems = items.filter((item) => item.checked);

    let dataToSend = [];
    let ids = [];
    selectedItems.forEach((item) => {
      let delivery = deliverFn(item);
      if (delivery !== false && item.delivered === false) {
    //   if (delivery !== false) {
        dataToSend.push(delivery);
        ids.push(item.id);
      }
    });
// console.log(dataToSend);
    let key;
    let menzberg = '209b3432-3b2b-4031-b93e-ee8c60a55558';
    let tpnt = 'f43c220d-8583-44f6-b4c2-0847408b5f39';

    if (via === 'menzberg') {
      key = menzberg;
    } else if (via === 'tpnt') {
      key = tpnt;
    }

    if (dataToSend.length > 0) {
    //   await fetch('http://localhost:8080/api/forward-request', {
      await fetch('https://om.menzbergorders.com/api/forward-request', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ data: dataToSend, key: key }),
      })
        .then(async (response) => response.json())
        .then((response) => {
            console.log('forward response: ', response.status)
          if (response.status === 201) {
            setCheckAll(false);
            ids.forEach(async (i) => {
              await updateDoc(doc(db, 'orders', `${i}`), {
                delivered: true,
              });
            });
            setMessageState('success');
            messageDisplay('Les commandes ont été transmises avec succès');
          }
        });
    } else if (selectedItems.length !== dataToSend.length) {
      setMessageState('error');
      messageDisplay("Impossible de transmettre la/les commandes");
    } else {
      setMessageState('error');
      messageDisplay('Aucun élément sélectionné à transmettre');
    }
    setIsLoading(false)
  };

  async function handleLogout() {
    try {
      await logout();
      navigate('/login');
    } catch (error) {
      console.error(error);
    }
  }

  const { logout } = useAuth();
  let navigate = useNavigate();

  const archiveHandler = (e) => {
    const selectedItems = items.filter((item) => item.checked);
    selectedItems.forEach(async (item) => {
      await updateDoc(doc(db, 'orders', `${item.id}`), {
        archived: e? false: true,
      });
    });
  };

    const fulfimentHandler = ()=>{
        const selectedItems = items.filter((item) => item.checked);
        if (selectedItems.length > 0){
            selectedItems.forEach(async (item) => {
            await updateDoc(doc(db, 'orders', `${item.id}`), {
                'order.fulfillmentStatus': 'FULFILLED'
            });
            });
            // console.log(selectedItems)
            setMessageState('success');
            messageDisplay('Les commandes ont été marquées avec succès comme traitées');
        }else{
            setMessageState('error');
            messageDisplay("Aucun élément sélectionné à traiter");
        }
    }

  return (
    <>
      <div className="container">
        <div className="top">
          <div className="left">
            <h1 id="logo">Menzberg</h1>
          </div>

          <div className="right">
            <button onClick={handleLogout}>se déconnecter</button>
            <button onClick={() => setShowArchived(!showArchived)}>
              {showArchived ? 'commandes Active' : 'commandes archivées'}
            </button>
          </div>
        </div>

        <div className="top">
            <div className="left">
                <button onClick={()=>archiveHandler(showArchived)}>{showArchived ? "déplacer hors de l'archive" : "déplacer vers l'archive"}</button>
            </div>
            <div id="successMessage" className={messageState}>
                {message}
            </div>
            <div className="right">
                <button onClick={fulfimentHandler}>marquer comme traité</button>
                <button disabled={isLoading} onClick={() => forward1Handler('tpnt')}>Livrée TPNT</button>
                <button disabled={isLoading} className="main" onClick={() => forward1Handler('menzberg')}>Livrée Menzberg</button>
            </div>
        </div>

        <table id="ordersTable">
          <thead>
            <tr>
              <th>
                <input
                  type="checkbox"
                  name="check-all"
                  id="check-all"
                  checked={checkAll}
                  onChange={handleCheckAll}
                />
              </th>
              <th>
                <label htmlFor="check-all">Commande</label>
              </th>
              <th>Client</th>
              <th>Traitement</th>
              <th>Méthode d'expédition</th>
              <th>Total</th>
              <th>Éléments</th>
              <th>Livraison</th>
              <th>De</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item) => (
              <Row key={item.id} item={item} onItemCheck={handleItemCheck} />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Home;
