import React from 'react'

const Popup = ({ lineItems }) => {
    // console.log('=========', lineItems )
    // console.log('=========', lineItems[0]   )
    // console.log('=========', lineItems[0].descriptionLines[0].plainText.original   )

    // lineItems.forEach(item => {
    //     item.descriptionLines.map((option, i) => 
    //         console.log('-', option.name.original)
    //     )
    // });
    function extractImageName(url) {
        const regex = /\/v1\/([^#]+)/;
        const match = url.match(regex);
        return match ? match[1] : null;
      }
      
    //   const url = "wix:image://v1/54b8db_347e1275f8ab4b91b499ba0f61202394~mv2.png#originWidth=1080&originHeight=1080";
    //   const imageName = extractImageName(url);
  return (
    <div className='popup'>
        <h3>Éléments</h3>
        <div className="elements">
            {
                lineItems.map((item, index) => (
                    <div className='element' key={index}>
                        <div>
                            <div><img alt={'product image' + item.productName.original} src={'https://static.wixstatic.com/media/'.concat(extractImageName(item.image)).concat('/v1/fill/w_43,h_43,al_c,q_80,usm_0.66_1.00_0.01,enc_auto/').concat(extractImageName(item.image))}/></div>
                            <div className="disc">
                                <h4>{item.productName.original}</h4>
                                <ul>
                                    {
                                        item.descriptionLines.map((option, i) => (
                                            <li key={i}>{option.name?.original}: {option.plainText?.original}  {option?.color}</li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                        <div>X{item.quantity}</div>
                    </div>
                ))
            }
        </div>
    </div>
  )
}

export default Popup