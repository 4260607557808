import  { initializeApp }  from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDbaeHEYJRiPAQ9pQg7x7xI7suM65rDiS8",
    authDomain: "menzberg-95ec3.firebaseapp.com",
    projectId: "menzberg-95ec3",
    storageBucket: "menzberg-95ec3.appspot.com",
    messagingSenderId: "761849956058",
    appId: "1:761849956058:web:f86820c33c463e80b187e3"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig)
// export const firestore = getFirestore(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app)
export default app